import chalk from 'chalk';

import { environment } from 'common/environment';

const breakExecution = (...text: string[]) => {
  if (environment.mode === 'Development') {
    try {
      // This error was thrown as a convenience so that if you enable
      // "break on all exceptions" in your console,
      // it would pause the execution at this line.
      throw new Error([...text].join());
      // tslint:disable-next-line: no-empty
    } catch {}
  }
};

const error = (...text: string[]) => {
/////////////////////////
  // tslint:disable-next-line:no-console
  console.log(`%c${text}`, 'color: red');
///////////
////////////////////////////////////////
//////////////////////////////////
////////////

  breakExecution();
};

const warning = (...text: string[]) => {
/////////////////////////
  // tslint:disable-next-line:no-console
  console.log(`%c${text}`, 'color: orange');
///////////
////////////////////////////////////////
/////////////////////////////////////
////////////

  breakExecution();
};

const info = (...text: string[]) => {
/////////////////////////
  // tslint:disable-next-line:no-console
  console.log(`%c${text}`, 'color: blue');
///////////
////////////////////////////////////////
///////////////////////////////////
////////////
};

const debug = (...text: string[]) => {
/////////////////////////
  // tslint:disable-next-line:no-console
  console.log(`%c${text}`, 'color: green');
///////////
////////////////////////////////////////
////////////////////////////////////
////////////
};

const raw = (...text: string[]) => {
  // tslint:disable-next-line:no-console
  console.log(...text);
};

export const log = { error, warning, info, debug, raw };
