import 'regenerator-runtime/runtime';

// tslint:disable:ordered-imports - sequence is important for styles.
import 'normalize.css';
import 'applications/spa/index.scss';
// tslint:enable:ordered-imports

import { environment } from 'common/environment';
import { log } from 'common/log';

/**
 * Fetch the main application async, after the css
 * has loaded. This results in a nicely styled loading page.
 */
const applicationStartUp = async () => {
  log.info('Staring Rock, Paper, Spock - the Game');
  log.info(`Version ${environment.branch}:${environment.version}`);
  log.info(`Server: ${environment.url}:${environment.serverPort}`);

  const bootstrap = await import('applications/spa/Bootstrap');
  await bootstrap.Bootstrap();
};

applicationStartUp()
  .then(() => {
    log.info('Application started');
  })
  .catch((ex) => {
    log.warning(`Application crashed: ${ex}`);
  });
